import React from "react"
import Layout from '../../components/layout'; 
import DropdownMenu from '../../components/dropdownMenu'
import productStyles from '../../styles/product.module.scss'
import image from '../../images/vector-image-3.jpg';
import dropdownStyles from '../../styles/dropdown.module.scss'

const BuroemPage = () => {
    return(
        <Layout>
            <DropdownMenu/>
            <div className={productStyles.descripcion}>
                <h3>Buró / Círculo de Crédito</h3>
                <p className={productStyles.texto}>Reporte de sociedad y accionistas (acreditados) de la opinión crediticia de Buró de crédito.</p>
                <img className={dropdownStyles.image} src={image} alt="imagen productos" /> 
            </div>
            
        </Layout>
    ) 
}
export default BuroemPage;